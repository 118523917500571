import React, { useState } from "react"
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap"
import addToMailchimp from "gatsby-plugin-mailchimp"

const SignUpComponent = () => {
  const [message, setMessage] = useState("")

  const [email, setEmail] = useState("")
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSubmit = event => {
    event.preventDefault()
    addToMailchimp(email)
      .then(data => {
        handleShow()
        setMessage(data.msg)
        console.log(data)
        // alert(`${data.result}\n${data.msg}`);
      })
      .catch(error => {
        // Errors in here are client side
        // Mailchimp always returns a 200
        console.log(error)
      })
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Newsletter Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <section id="sign-up" className="bg-dark">
        <Container className="py-4 py-lg-5">
          <Row className="d-flex justify-content-center">
            <Col md="6">
              <div className="card">
                <div className="card-body">
                  <h3 className="mb-3">Sign up for updates!</h3>
                  <p>
                    Stay in the loop with POCAM's efforts to make real, lasting
                    change in our industry. We promise we'll only send you
                    important information, and you can unsubscribe at any time.
                  </p>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Control
                        size="lg"
                        type="email"
                        name="email"
                        placeholder="youremail@domain.com"
                        onChange={handleEmailChange}
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default SignUpComponent
